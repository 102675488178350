import * as types from "../types/types"
import { combineReducers } from "redux";

const user=  (state = 0, action) => {  
    switch (action.type) {
      case types.CHANGE_LOGIN:
        return action.id
      default:
        return state
    }
  }

  const name=  (state = "", action) => {  
    switch (action.type) {
      case types.CHANGE_NAME:
        return action.value
      default:
        return state
    }
  }

  export default combineReducers({
      user,
      name
  })
